import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import * as cocossd from "@tensorflow-models/coco-ssd";
import Webcam from "react-webcam";
import "./App.css";

function App() {
  const videoref = useRef(null);
  const canvasRef = useRef(null);

  const startDetection = async () => {
    const net = await cocossd.load();
    setInterval(() => {
      detect(net);
    }, 10);
  };

const drawRect = (detections, ctx) =>{
  detections.forEach(prediction => {

    const [x, y, width, height] = prediction['bbox']; 
    const text = prediction['class']; 

    const color ="#ff00ff"
    ctx.strokeStyle =  color
    ctx.font = '18px Arial';

    ctx.beginPath();   
    ctx.fillStyle =  color
    ctx.fillText(text, x, y);
    ctx.rect(x, y, width, height); 
    ctx.stroke();
  });
}
  const detect = async (net) => {
    if (
      typeof videoref.current !== "undefined" &&
      videoref.current !== null &&
      videoref.current.video.readyState === 4
    ) {
      const video = videoref.current.video;
      const videoWidth = videoref.current.video.videoWidth;
      const videoHeight = videoref.current.video.videoHeight;

      videoref.current.video.width = videoWidth;
      videoref.current.video.height = videoHeight;

      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      const obj = await net.detect(video);

      const ctx = canvasRef.current.getContext("2d");
      drawRect(obj, ctx); 
    }
  };

  useEffect(()=>{startDetection()},[]);

  return (
    <div className="App">
      <header className="App-header">
        <Webcam
          ref={videoref}
          muted={true} 
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 1024,
            height: 768,
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 8,
            width: 1024,
            height: 768,
          }}
        />
      </header>
    </div>
  );
}

export default App;
